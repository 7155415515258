<template>
  <a-drawer
    :width="440"
    title="详情"
    placement="right"
    :visible="visible"
    @close="close"
  >
    <div class="linehead-title"> 
      <span class="left"></span>文件信息
    </div>
    <!-- 文件信息 -->
    <div class="folder-message">
      <!-- 顶部 -->
      <div class="message-top">
        <img class="folder-img" src="@/assets/images/eleSeal/folder.png" alt="">
        <div class="message-box">
          <div class="message-title">{{sealInfo.fileName}}</div>
          <div class="folder-number">文件编号：</div><span>{{sealInfo.contractNo}}</span>
        </div>
      </div>
      <!-- 签署信息 -->
      <div class="signature-box">
        <div class="signature-status">签署状态：</div>
        <span :style="{background: signStatus[sealInfo.status]?.color}" class="status-icon"></span>
        <span>{{signStatus[sealInfo.status]?.status}}</span>
      </div>
      <!-- 签署信息 -->
      <div class="signature-box top-margin-8px">
        <div class="signature-status">签署截止日期：</div>
        <span>{{sealInfo.expireTime}}</span>
      </div>
      <!-- 印章名称 -->
      <div class="signature-box top-margin-8px">
        <div class="signature-status">印章名称：</div>
        <span>{{sealInfo.sealName}}</span>
        <div class="examine-seal">
          <!-- <div v-viewer="otherOptions" :images="sealInfo?.fullSealImage" style="cursor:pointer;position: relative;">
            查看印章<img class="small-img" :src="sealInfo?.fullSealImage" alt="" />
          </div> -->
          </div>
      </div>
    </div>

    <div class="linehead-title top-margin-24px"> 
      <span class="left"></span>附件信息
    </div>
    <!-- 附件信息 -->
    <div class="download-message" v-for="item in sealInfo.documentApplyFileList" :key="item">
      <div class="download-box" v-if="item.suffix ==='doc' || item.suffix ==='docx'">
        <img class="file-img" :src="word" alt="">
        <div class="gray-box" @click="downloadFileItem(item)"><img :src="download" alt=""></div>
        <div class="download-title">{{item.fileName}}</div>
      </div>
      <div class="download-box" v-if="item.suffix ==='pdf'">
        <img class="file-img" :src="pdf" alt="">
        <div class="gray-box" @click="downloadFileItem(item)"><img :src="download" alt=""></div>
        <div class="download-title">{{item.fileName}}</div>
      </div>
      <div class="download-box" v-if="item.suffix ==='xls' || item.suffix ==='xlsx'">
        <img class="file-img" :src="excel" alt="">
        <div class="gray-box" @click="downloadFileItem(item)"><img :src="download" alt=""></div>
        <div class="download-title">{{item.fileName}}</div>
      </div>
      <div class="download-box" v-if="item.suffix ==='png' || item.suffix ==='jpg' || item.suffix ==='jpeg'">
        <div v-viewer="otherOptions" :images="[item.fullFile]" style="cursor:pointer;">
          <img class="file-img" :src="item.fullFile" alt="">
        </div>
        <div class="gray-box" @click="downloadFileItem(item)"><img :src="download" alt=""></div>
        <div class="download-title">{{item.fileName}}</div>
      </div>
    </div>

    <div class="linehead-title top-margin-24px"> 
      <span class="left"></span>签署信息
    </div>
    <div v-for="item in sealInfo.signatories" :key="item">
      <a-card v-if="item.type === 0" title="发起方" type="inner" style="width: 400px;margin-bottom: 9px;">
        <template #extra>
          <span v-if="item.currentStatus === 1" style="color: #FAAD14;">签署中</span>
          <span v-if="item.currentStatus === 2" style="color: #52C41A;">已签署</span>
          <span v-if="item.currentStatus === 3" style="color: #969799;">已过期</span>
        </template>
        <div class="introduce-box">
          <div class="left-introduce">企业名称：</div>
          <div class="right-message">{{item.name}}</div>
        </div>
      </a-card>

      <a-card v-if="item.type === 1" title="接收方" type="inner" style="width: 400px;margin-bottom: 9px;">
        <template #extra>
          <span v-if="item.currentStatus === 1" style="color: #FAAD14;">签署中</span>
          <span v-if="item.currentStatus === 2" style="color: #52C41A;">已签署</span>
          <span v-if="item.currentStatus === 3" style="color: #969799;">已过期</span>
        </template>
        <div class="introduce-box" v-if="item.signatoryType === 0">
          <div class="left-introduce">企业名称：</div>
          <div class="right-message">{{item.name}}</div>
        </div>
        <div class="introduce-box" v-if="item.signatoryType === 0">
          <div class="left-introduce">经办人姓名：</div>
          <div class="right-message">{{item.operatorName}}</div>
        </div>
        <div class="introduce-box" v-if="item.signatoryType === 1">
          <div class="left-introduce">个人姓名：</div>
          <div class="right-message">{{item.operatorName}}</div>
        </div>
        <div class="introduce-box">
          <div class="left-introduce">联系方式：</div>
          <div class="right-message">{{item.operatorContact}}</div>
        </div>
      </a-card>
    </div>
    <div style="width: 100%;height:30px"></div>
    <div
      :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'right',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      }"
    >
      <a-button style="margin-right: 8px" class="MyCancel" @click="close">取消</a-button>
      <a-dropdown :trigger="['click']">
        <a-button type="primary" style="margin-right: 8px" :ghost="sealInfo.status === 1" @click.prevent="downloadFile" >下载<DownOutlined v-if="sealInfo.status ===3" /></a-button>
        <template #overlay>
          <a-menu v-if="sealInfo.status === 3">
            <a-menu-item key="0" @click="downloadCheckbox(1)">下载盖印文件</a-menu-item>
            <a-menu-item key="1" @click="downloadCheckbox(2)">下载存证报告</a-menu-item>
            <a-menu-item key="2" @click="downloadCheckbox(3)">全部下载</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      
      <a-button type="primary" v-if="sealInfo.status === 1" @click="signature">签署</a-button>
    </div>

  </a-drawer>
</template>

<script>
import { watch, reactive, toRefs, onMounted } from 'vue'
import word from '@/assets/images/word.png'
import pdf from '@/assets/images/pdf.png'
import excel from '@/assets/images/excel.png'
import download from '@/assets/images/eleSeal/download.png'
import { fileDetail, downloadItem } from '@/apis/eleSeal.js'
import { signStatus } from '@/utils/constData.js'
import { DownOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    DownOutlined
  },
  props: {
    current: {
      type: Number
    },
    visible: {
      type: Boolean
    }
  },
  emits: ['close','goIframe'],
  setup(props, { emit }) {
    const state = reactive({
      sealInfo: {}
    })

    // 关闭窗口
    const close = () => {
      emit('close', false)
    }

    // 获取详情
    const getDetail = async (id) => {
      console.log(id);
      const res = await fileDetail(id)
      console.log('res', res);
      state.sealInfo = res.data
      console.log(state.sealInfo.expireTime);
      state.sealInfo.expireTime = state.sealInfo.expireTime.split(' ')[0]
    }

    // 查看印章
    const checkSeal = () => {
      console.log('查看印章');
    }

    // 下载
    const downloadFile = () => {
      console.log('state.sealInfo',state.sealInfo.status);
      if (state.sealInfo.status === 3) {
        return
      }
      downloadCheckbox(0)
    }

    // 点击详情中的文件下载
    const downloadFileItem = (item) => {
      console.log('下载', item);
      window.open(item.fullFile)
    }

    // 下载已完成
    const downloadCheckbox = async (check) => {
      const item = state.sealInfo
      const res = await downloadItem({id: item.id, fileType: check})
      const link = document.createElement("a");
      // let blob = new Blob([res], { type: "application/zip" });  //文件流处理
      let fileName = decodeURIComponent(res.headers['content-disposition'].split('=')[1]) //拆解获取文件名，
      let blob = res.data;  //文件流处理
      link.style.display = "none";  //去除a标签的样式设置连接
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      // 模拟点击事件
      link.click();
    }

    // 签署
    const signature = () => {
      emit('close', false)
      emit('goIframe', {
        id: state.sealInfo.id,
        fileName: state.sealInfo.fileName
      })
    }

    watch(
      () => props.current,
      (newValue) => {
        if (newValue) {
          getDetail(newValue)
        }
      },
      {
        deep: true,
        immediate: true
      }
    )
    return {
      close,
      ...toRefs(state),
      word,
      pdf,
      excel,
      download,
      checkSeal,
      downloadFile,
      signature,
      signStatus,
      otherOptions: {
        navbar: false,
        title: false,
        transition: false
      },
      downloadCheckbox,
      downloadFileItem,
    }
  }
}
</script>

<style scoped lang="less">
// 上间距
.top-margin-8px {
  margin-top: 8px;
}
.top-margin-24px {
  margin-top: 24px;
}

.linehead-title {
  margin-bottom: 16px;
  font-weight: 600;

  .left {
    width: 2px;
    height: 12px;
    background: #C3161C;
    display: inline-block;
    margin-right: 4px;
    
  }
}

.folder-message {
  .message-top {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    img {
      width: 40px;
      height: 40px;
    }
    .message-box {
      width: 340px;
      .message-title {
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #1A1A1B;
        line-height: 20px;
        overflow: hidden; 
        white-space: nowrap; 
        text-overflow: ellipsis;
      }

    }
    .folder-number {
      height: 18px;
      font-size: 12px;
      font-weight: 400;
      color: #595959;
      line-height: 18px;
      display: inline-block;
    }
    span {
      height: 18px;
      font-size: 12px;
      font-weight: 400;
      color: #131314;
      line-height: 18px;
    }
  }

  .signature-box {
    display: flex;
    // height: 22px;
    .signature-status {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0,0,0,0.65);
      line-height: 22px;
      display: inline-block;
    }
    .status-icon {
      width: 6px;
      height: 6px;
      display: inline-block;
      margin: 8px 8px 2px 0;
      border-radius: 3px;
    }
    span {
      width: 226px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0,0,0,0.65);
      line-height: 22px;
      display: inline-block;
    }
    .examine-seal {
      margin-left: 30px;
      color: #1890FF;
      height: 22px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      cursor: pointer;
    }
  }
}

// 附件信息
.download-message {
  .download-box {
    width: 100%;
    height: 44px;
    display: flex;
    margin-bottom: 8px;
    position: relative;
    &:hover {
      .gray-box {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:last-child {
      margin-bottom: 3px;
    }
    
    .file-img {
      width: 44px;
      height: 44px;
      margin-right: 8px;
    }

    .gray-box {
      width: 44px;
      height: 15px;
      background: #000000;
      border-radius: 0px 0px 3px 3px;
      opacity: 0.45;
      position: absolute;
      bottom: 0;
      display: none;
      cursor: pointer;

      img {
        width: 9px;
        height: 8px;
      }
    }

    .download-title {
      width: calc(100% - 52px);
      line-height: 44px;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
    }
  }
}


// 签署信息样式
.introduce-status {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}
.introduce-box {
  display: flex;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0px;
  }
  .left-introduce {
    width: 84px;
    word-break: keep-all;
  }
}

// 卡片样式
.card-box {
  width: 100%;
  background-color: pink;
}
.small-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

</style>